.ui-title {
  color: #000;
  font-style: normal;
  font-weight: 600;

  &--h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  &--h2 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 28px;
  }

  &--h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  &--h4 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}
