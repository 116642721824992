.logo {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 171px;
  background-image: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-color: transparent;
  margin-bottom: 8px;
}
