@import "../../../components/UI/styles/palette.scss";

.sidebar {
  padding: 0;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  border-right: 1px solid rgba(166, 172, 185, 0.3);
  background: $grey-color-5;
  transition: left;

  ul {
    border-right: none;
    background: none;
  }

  button {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }

  &-hr {
    position: relative;
    margin: 12px 0;
    background-color: #a6acb9;
    height: 1px;
    color: #e5e7eb;

    &::after {
      content: "BETA";
      color: #a6acb9;
      position: absolute;
      right: 8px;
      top: -13px;
      font-size: 10px;
      line-height: 10px;
    }
  }
}

.ant-layout-sider-collapsed {
  width: 64px !important;
  max-width: 64px !important;
  min-width: 64px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  height: 54px !important;
  margin: 0 !important;
}

.ant-menu .ant-menu-item-selected {
  background-color: #dfebfd !important;
}

.ant-menu .ant-menu-item-selected a,
.ant-menu-item a {
  color: #000 !important;
}

.ant-menu-item a:hover {
  color: #0057ac !important;
}

.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid #43bfe8 !important;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon {
  display: inline-flex !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 12px) !important;
}

.ant-menu .ant-menu-item .icon {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: rgba(0, 87, 172, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-sider-collapsed button {
  right: 20px;
}
