.vacancies-container {
  position: relative;
  height: 100%;
  padding: 24px;
  line-height: 18px !important;
  font-style: normal !important;

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-row {
    &--disabled {
      color: rgba(166, 172, 185, 1);
      pointer-events: none;

      div {
        color: rgba(166, 172, 185, 1);
      }
    }
  }

  &-list {
    margin-top: 36px;
    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-filters {
        padding-bottom: 20px;
      }
    }

    &-spinner {
      justify-content: center;
      margin-top: 80px;
    }
  }

  &-candidateCount {
    display: flex;
    align-items: center;
  }
}
