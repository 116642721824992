/*Sidebar*/
.root-sidebar {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.root-block {
  background: gray;
  opacity: 0.7;
  right: 0;
  top: 0;
  grid-area: 1 / 1 / 2 / 3;
  z-index: 1000;
}
.reply-sidebar {
  background: #e6e6e6;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 460px;
  height: 100vh;
  padding: 5px 27px 15px 27px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}
.reply-navigation {
  max-width: 400px;
  background: #e6e6e6;
  position: fixed;
  top: 0;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.reply-navigation__item {
  margin-right: 30px;
  padding: 5px 5px;
  cursor: pointer;
  font-size: 14px;
}
.reply-navigation__item:hover {
  border-bottom: 2px solid #6366f1;
}
.reply-navigation__item--active {
  border-bottom: 2px solid #6366f1;
  font-weight: 500;
  color: #4f46e5;
}
.reply-content {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
.reply-content--vacancy {
  padding-bottom: 80px;
}
.reply-content--reply {
  padding-bottom: 215px;
}
.reply-info {
  display: flex;
  flex-direction: column;
}
.reply-info__title {
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
  margin: 0;
}
.reply-info__block {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e5e7eb;
  align-items: center;
}
.reply-info__block .erycgI {
  margin: 0;
}
.reply-block__title {
  color: #6b7280;
  font-size: 14px;
}
.reply-block__text {
  color: #111827;
  font-size: 14px;
  text-align: right;
}
.edit-manager__icon {
  cursor: pointer;
  margin-left: 0.5rem;
}
.reply-block__link {
  color: #1e40af;
  font-size: 14px;
  cursor: pointer;
}
.reply-block__sms {
  margin-left: 150px;
  height: 20px;
  color: #1e40af;
  font-size: 14px;
  cursor: pointer;
}
.reply-info__status {
  padding: 2px 10px;
}
.reply-block__address {
  text-align: right;
  max-width: 170px;
}
.reply-description {
  padding-top: 12px;
}
.description__title {
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  padding-bottom: 12px;
  margin: 0;
  padding-top: 6px;
}
.reply-description__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
  margin: 0;
}
ul {
  margin: 0;
  /*padding-left: 30px;*/
}
.reply-description__text {
  color: #6b7280;
  font-size: 14px;
  word-wrap: break-word;
}
.reply-description__utm {
  white-space: pre-line;
}
.reply-description__text p {
  margin: 0;
  white-space: pre-wrap;
}

.reply-description__block {
  padding-top: 20px;
}

.vacancy-edit {
  background: #e6e6e6;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
}
.vacancy-edit__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  background: #4f46e5;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.vacancy-edit__button:hover {
  background: #5a53e3;
  color: #ffffff;
}
.reply-responses {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 15px;
  padding-bottom: 36px;
}

.reply-responses__title {
  margin: 0;
  font-size: 16px;
  color: #111827;
}
.reply-responses__quantity {
  font-size: 24px;
  color: #4f46e5;
  font-weight: 600;
}
.responses-block {
  width: fit-content;
}

/*notification*/
.reply-warning {
  background: #fffbeb;
  border-left: 4px solid #fbbf24;
  display: flex;
  align-items: flex-start;
  padding: 16px 48px 16px 30px;
  font-size: 14px;
  color: #92400e;
  margin-bottom: 20px;
}

.reply-error {
  background: #fef2f2;
  border-left: 4px solid #f87171;
  display: flex;
  align-items: flex-start;
  padding: 16px 48px 16px 30px;
  font-size: 14px;
  color: #92400e;
  margin-bottom: 20px;
}
.reply-error__title {
  color: #991b1b;
  font-weight: 500;
}
.reply-new {
  background: #fffbeb;
  border-left: 4px solid #fbbf24;
  display: flex;
  align-items: flex-start;
  padding: 16px 16px 16px 16px;
  font-size: 14px;
  color: #92400e;
  margin-bottom: 20px;
}
.reply-new__title {
  color: #6e3417;
  font-weight: 500;
}
.reply-new__notification {
  display: flex;
  flex-direction: column;
}

/*Statuses Sidebar*/
.statuses-sidebar {
  background: #e6e6e6;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
  padding-bottom: 17px;
}
.statuses-sidebar__title {
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  padding-bottom: 11px;
  margin: 0;
}
.edit-status {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 6px;
}

.chat-icon {
  margin-right: 6px;
  height: 1.25rem;
}

.reply-history {
  padding-top: 50px;
}

.reply-activity {
  height: 100%;
}

.reply-comments {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.comment-controls {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  width: 400px;
}

.comment-button {
  padding-top: 1rem;
}

.comment-field {
  width: 100%;
}

.comment-button__icon {
  font-size: 1.15rem;
  color: #6b7280;
  margin-left: 1rem;
}

.comment-button__icon:hover {
  color: #4f46e5;
}

.activity-navigation {
  max-width: 400px;
  background: #e6e6e6;
  position: fixed;
  top: 50px;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.activity-navigation__item {
  margin-right: 30px;
  padding: 5px 5px;
  cursor: pointer;
  font-size: 14px;
}
.activity-navigation__item:hover {
  border-bottom: 2px solid #6366f1;
}
.activity-navigation__item--active {
  border-bottom: 2px solid #6366f1;
  font-weight: 500;
  color: #4f46e5;
}

.comment-list {
  max-height: calc(100vh - 54px - 54px - 92px - 32px);
  overflow-y: auto;
  margin-right: -24px;
  padding-right: 24px;
}

.comment-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.comment-card__bordered {
  border: 1px solid #c7c3c3;
  padding: 0.5rem;
}

.comment-card__shadow {
  box-shadow: 0 7px 8px 0 rgb(0 0 0 / 6%);
  padding: 0.75rem;
}

.comment-card__name {
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
}

.comment-card__attrs {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.comment-card__message {
  font-size: 0.875rem;
}

.reply-activity-all {
  padding-top: 50px;
}

.pagination {
  text-align: center;
  cursor: pointer;
  color: #4f46e5;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.status-button {
  border-radius: 6px;
  border: 1px solid #d1d5db;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
  outline: none;
  margin-right: 0.2rem;
}

.status-button--disabled {
  cursor: not-allowed;
  background: #f3f4f6 !important;
  color: #1f2937 !important;
}

.not-throw-popup {
  padding: 0.5rem 1rem;
}

.not-throw-reason {
  cursor: pointer;
}
/* chat*/
.reply-chat-tab {
  height: 89vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
}

.header {
  padding: 15px 15px 15px 30px;
}

.chat-list {
  flex: 1;
  overflow: auto;
}

.empty-chat-image {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-typography.empty-chat-text {
  width: 240px;
  height: 54px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 133.02%;
  text-align: center;
  color: #9ca3af;
}

.input-container {
  display: flex;
  align-items: center;
  padding-left: 15px;
  background: #fcfcfc;
  border: 1px solid #d3d3d3;
  height: 76px;
  position: relative;
  min-width: 460px;
  bottom: -31px;
}
.input-container .ant-input {
  padding: 0;
  min-height: 34px;
  border-radius: 10px;
}
.input-container .ant-input::placeholder {
  padding-left: 15px;
}
.btn-send-message {
  margin: 0 30px 0 20px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
}
.input-container .ant-input-group-addon {
  margin: 0;
  padding: 0;
}
.ant-modal-close {
  top: -10px;
  right: -10px;
}

.round-icon {
  border-radius: 50%;
}

.typing-animation {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 4px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #9ca3af;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.dot-1 {
  animation-name: typing-animation-1;
}

.dot-2 {
  animation-name: typing-animation-2;
  animation-delay: 0.33s;
}

.dot-3 {
  animation-name: typing-animation-3;
  animation-delay: 0.67s;
}

.chat-message {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

/*.custom-chat-list {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: flex-start;*/
/*  color: rgba(0, 0, 0, 0.85);*/
/*}*/

.chat-list .reply-chat-message.reply-own-message {
  margin-left: 50%;
  justify-content: flex-start;
  width: 50%;
  border: none;
  margin-bottom: 12px;
}
.chat-list .reply-chat-message.reply-other-message {
  justify-content: flex-end;
  width: 60%;
  border: none;
}

@keyframes typing-animation-1 {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes typing-animation-2 {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes typing-animation-3 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.3;
  }
}
