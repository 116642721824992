@import "../../components/UI/styles/palette.scss";

.plan-table {
  border-radius: 10px;
  border-collapse: collapse;
  border: 1px solid $grey-color-3;
  table-layout: fixed;
  font-size: 14px;
  font-weight: 500;

  &-td-active {
    background: $blue-color-3;
  }

  tr,
  td {
    border: 1px solid $grey-color-3;
    padding: 14px;
  }

  tr td:first-child {
    color: $grey-color-7;
  }

  tr td:not(first-child) {
    width: 210px;
  }

  &-name {
    color: $blue-color-9;
    cursor: pointer;
  }
}
