.replies-table {
  margin-top: 40px;
  position: relative;
  height: 100%;

  &-column {
    &-name {
      width: 135px;
      min-width: 135px;
    }

    &-address {
      width: 243px;
      min-width: 243px;
    }

    &-info {
      width: 103px;
      min-width: 103px;
    }
  }

  &-row {
    &--disabled {
      color: rgba(166, 172, 185, 1);
      pointer-events: none;

      div {
        color: rgba(166, 172, 185, 1);
      }
    }
  }

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-link-dropdown-trigger {
    cursor: pointer;
  }
}
