@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-checkbox {
  margin-left: 0 !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $blue-color-9 !important;
    border-color: $blue-color-9 !important;
  }
  &--disabled {
    @include disabled;
  }
}
