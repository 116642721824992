@import "../UI/styles/palette.scss";
@import "../UI/styles/mixins.scss";

.header {
  border-bottom: 1px solid rgba(166, 172, 185, 0.3);
  padding: 0 24px;
  height: 172px;
  width: 100%;
  background: $white-color;
  display: flex;
  justify-content: space-between;
  z-index: 1000;

  .title {
    margin-top: 50px;
    max-width: 800px;
    @include nowrap;
  }

  .back-btn {
    color: #a6acb9;
    font-size: 14px;
    background: none;
    padding: 0;
    box-shadow: none;
  }

  .user {
    display: flex;
    align-items: center;
    height: 24px;
    margin-top: 16px;
  }

  .user-dropdown {
    margin-right: 8px;
    cursor: pointer;
  }

  .user-dropdown,
  .caption {
    line-height: 20px;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
  }

  .caption {
    color: #a6acb9;
    margin-right: 17px;
    position: relative;
    margin-bottom: 0;
  }

  .caption::after {
    position: absolute;
    top: 0;
    right: -8px;
    display: block;
    content: "";
    width: 1px;
    height: 20px;
    background-color: #a6acb9;
  }
}
