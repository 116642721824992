@import "./palette.scss";

@mixin nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin global-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    padding: calc((100% - 98px) / 2) 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #43bfe8;
    border-radius: 2px;
  }
}

@mixin position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex-block($alignItems: center, $justifyContent: center) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin border($color, $width: 1px) {
  border: $width solid $color;
}

@mixin square($size, $radius: 0) {
  width: $size;
  height: $size;

  @if $radius != 0 {
    border-radius: $radius;
  }
}

@mixin fakeContent($bg-color, $right: 0) {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: $right;
  background-color: $bg-color;
  width: 1px;
  height: 100%;
}

@mixin animation($delay: 2s) {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  animation: spin $delay linear infinite;
}

// UI components mixins

@mixin hover {
  border-color: $border-hover-color;
  color: $hover-color;
  box-shadow: none;

  &:not([disabled]):hover {
    @content;
  }
}

@mixin disabled {
  color: $disabled-color;
  background-color: $bg-disabled-color;
}

@mixin focus {
  border-color: $border-focus-color;
  box-shadow: none;
  color: $focus-color;
}

@mixin error {
  color: $error-color;
  border-color: $border-error-color;
}

@mixin label {
  color: $label-color !important;
  height: 20px;
  margin-bottom: 4px;
  font-weight: 600 !important;
  &::after {
    content: none !important;
  }
}
