@import "components/UI/styles/mixins.scss";
.replies-container {
  position: relative;
  height: 100%;
  padding: 24px;

  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  font-style: normal !important;

  &-spinner {
    @include position-absolute-center;
  }
}
