@import "components/UI/styles/palette.scss";

.replies-new-table {
  position: relative;
  height: 100%;

  &-head {
    display: flex;
    justify-content: space-between;
    &-transfer-btn {
      margin: 15px 0;
    }
  }

  &-column {
    &-name {
      width: 135px;
      min-width: 135px;
    }

    &-address {
      width: 243px;
      min-width: 243px;
    }

    &-info {
      width: 103px;
      min-width: 103px;
    }
    &-status {
      position: absolute;
      top: -17px;
      right: -17px;
      font-size: 11px;
      color: $red-10;
    }
  }

  &-row {
    &--disabled {
      color: rgba(166, 172, 185, 1);
      pointer-events: none;

      div {
        color: rgba(166, 172, 185, 1);
      }
    }
  }

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-link-dropdown-trigger {
    cursor: pointer;
  }
}
