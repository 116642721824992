@import "../styles/palette.scss";

.card {
  background-color: $white-color;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  display: flex;

  &--list {
    width: 100%;
    align-items: center;
    .card-title {
      margin-left: 8px;
    }
    .card-icon {
      width: 52px;
      height: 52px;
    }
  }
  &--grid {
    width: 320px;
    flex-direction: column;

    .card-title {
      margin-top: 16px;
    }

    .card-icon {
      width: 40px;
      height: 40px;
    }
  }

  &-icon {
    border-radius: 10px;
    background-color: $blue-color-3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-chevron {
    position: absolute;
    right: 16px;
  }

  &-title {
    line-height: 24px;
    font-weight: 600;
    color: $black-color;
    font-size: 16px;
  }
}
