.reply {
  padding: 0 25px;
}
.reply .ant-table-container {
  max-height: calc(100vh - 310px);
  box-shadow: 0 1px 2px 0 #0000000f;
  border-radius: 10px;
  border: 1px solid #e5e7eb !important;
}
.reply .ant-table-thead > tr > th {
  background: #f9fafb;
  text-transform: uppercase;
  color: #6b7280;
  font-weight: 600;
  letter-spacing: 2px;
  border-bottom: 1px solid #e5e7eb;
}
.reply .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 10px !important;
}
.reply .ant-table-cell-scrollbar {
  border-top-right-radius: 10px !important;
}

.reply .ant-table-cell {
  border-right: none !important;
}
.reply .ant-table-body {
  border-bottom-left-radius: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.table-candidate__name {
  color: #111827;
  font-weight: 500;
}
.table-candidate__phone {
  color: rgba(30, 64, 175, 0.8);
  max-width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.phone-icon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}
.email-icon {
  margin-right: 6px;
}
.avito-icon {
  height: 1rem;
  width: 1rem;
  margin-right: 6px;
}

.table-date {
  color: rgba(17, 24, 39, 0.7);
}
.vacancy-position {
  display: flex;
}
.table-position {
  color: #1e40af;
  max-width: max-content;
}
.table-position__id {
  color: rgba(30, 64, 175, 0.7);
}

.table-address {
  color: #6b7280;
}

.table-manager {
  cursor: pointer;
}

.ant-pagination {
  margin-bottom: 0 !important;
}

/*STATUS*/
.status {
  font-size: 12px;
  border-radius: 20px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  padding: 2px 10px;
}
.status-edit {
  border-radius: 6px;
  border: 1px solid #d1d5db;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
  outline: none;
}
.status-edit:hover {
  opacity: 0.8;
}
/*новый*/
.status--NEW {
  background: #fef3c7;
  color: #92400e;
}
/*собеседование*/
.status--INTERVIEW {
  background: #c8e09e;
  color: #2d3b12;
}
/*Кандидат*/
.status--CANDIDATE {
  background: #e0e7ff;
  color: #3730a3;
}
/*Отказано*/
.status--DENIED {
  background: #fee2e2;
  color: #991b1b;
}
/*Просрочен*/
.status--EXPIRED {
  background: #f3f4f6;
  color: #1f2937;
}

/*Передан в КЦ*/
.status--TRANSFERRED_TO_CC {
  background: #f3f4f6;
  color: #1f2937;
}
/*Оформлен*/
.status--DECORATED {
  background: #d1fae5;
  color: #065f46;
}
/*Отказ кандидата*/
.status--REFUSAL_CANDIDATE {
  background: #fee2e2;
  color: #991b1b;
}
/*Не выходит на связь*/
.status--DOESNT_GET_TOUCH {
  background: #dbeafe;
  color: #1e40af;
}
/*Нужно перезвонить*/
.status--CALLBACK {
  background: #fce7f3;
  color: #9d174d;
}
/*Не дозвонились*/
.status--NOT_THROUGH {
  background: #dbeafe;
  color: #1e40af;
}
/*Резерв*/
.status--RESERVE {
  background: #f3f4f6;
  color: #1f2937;
}
/*Передано МПП от КЦ*/
.status--TRANSFERRED_TO_HR_FROM_CC {
  background: #ffdab9;
  color: #442c2e;
}
/*Не обработан */
.status--NOT_PROCESSED {
  background: #faf0e6;
  color: #4c5356;
}
.status-edit--disabled {
  cursor: not-allowed;
  background: #f3f4f6;
  color: #1f2937;
}
.status-edit--disabled:hover {
  opacity: 1;
}
.status-icon {
  margin-left: 8px;
  cursor: pointer;
}

.notification-warning {
  position: absolute;
  background: #fffbeb;
  border-left: 4px solid #fbbf24;
  padding: 16px 48px 16px 30px;
  width: 300px;
  z-index: 10;
  color: #92400e;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}
.notification-error {
  position: absolute;
  background: #fef2f2;
  border-left: 4px solid #f87171;
  padding: 16px;
  width: 300px;
  z-index: 10;
  color: #92400e;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}
.notification-icon {
  margin-top: 5px;
  margin-right: 12px;
}

/*Popover*/
.ant-popover-inner-content {
  padding: 0;
}
.popover-info {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}
.popover-info__name {
  font-size: 14px;
  color: #111827;
  font-weight: 500;
}
.popover-info__role {
  color: #065f46;
  background: #d1fae5;
  border-radius: 10px;
  padding: 2px 10px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 500;
}
.popover-info__gray {
  color: #626973;
  background: #f3f4f6;
  border-radius: 10px;
  padding: 2px 10px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 500;
}
.popover-info__department {
  color: #6b7280;
  font-size: 14px;
}
.popover-actions {
  /*height: 56px;*/
  /*padding-top: 17px;*/
  padding: 0 24px 24px;
}
.popover-actions__email {
  /*padding: 20px 36px;*/
  /*border-right: 1px solid #e5e7eb;*/
  /*border-top: 1px solid #e5e7eb;*/
  color: #374151;
  display: flex;
}
.popover-actions__phone {
  /*padding: 20px 36px;*/
  /*border-top: 1px solid #e5e7eb;*/
  color: #374151;
  display: flex;
  margin-top: 12px;
}
.popover-actions__icons-phone {
  width: 16px;
  height: 16px;
  margin-right: 14px;
}
.popover-actions__icons-email {
  width: 20px;
  height: 20px;
  margin-right: 14px;
}

/*Modal*/
.reply-modal {
  display: flex;
  flex-direction: column;
}
.reply-modal__title {
  font-size: 18px;
  font-weight: 500;
  color: #111827;
}
.reply-modal__text {
  font-size: 14px;
  color: #6b7280;
}
.modal-select {
  width: 100%;
  margin-bottom: 24px;
}
.modal-button__cancel {
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 9px 17px;
  margin-right: 16px;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
}
.modal-button__ok {
  background: #4f46e5;
  border: 1px solid #4f46e5;
  padding: 9px 17px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}
.modal-button__ok-disabled {
  opacity: 0.3;
  background: #4f46e5;
  border: 1px solid #4f46e5;
  padding: 9px 17px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  cursor: not-allowed;
}
.modal-button__ok:hover {
  opacity: 0.9;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.button-container > *:not(:last-child) {
  margin-right: 16px;
}

.rounded-button {
  border-radius: 8px;
}
